var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "gallery-items"
  }, _vm._l(_vm.boards, function (board) {
    return _c('div', {
      key: board._id,
      staticClass: "gallery-item",
      on: {
        "click": function ($event) {
          return _vm.$emit('click', board);
        }
      }
    }, [_c('div', {
      staticClass: "gallery-item__inner"
    }, [_c('div', {
      staticClass: "gallery-item__img",
      style: {
        backgroundImage: `url('${board.thumb}')`
      }
    }), _c('div', {
      staticClass: "gallery-item__con"
    }, [_c('h3', {
      staticClass: "gallery-item__tit"
    }, [_vm._v(_vm._s(board.subject))]), _c('div', {
      staticClass: "gallery-item__info"
    }, [_c('strong', [_vm._v(_vm._s(board.meta.authorName))]), _c('span', [_vm._v(_vm._s(board.meta.workType))])]), _c('div', {
      staticClass: "gallery-item__txt"
    }, [_vm._v(_vm._s(board.summary))]), board.meta.buyEnabled ? _c('div', {
      staticClass: "gallery-item__btn-wrap"
    }, [_c('v-btn', {
      staticClass: "gallery-item__btn",
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return board.meta.buyUrl.apply(null, arguments);
        }
      }
    }, [_c('span', [_vm._v("작품보기")]), _c('i', {
      staticClass: "icon icon-arrow"
    })])], 1) : _vm._e()])])]);
  }), 0), _vm._t("pagination")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }