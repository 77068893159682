<template>
<div>
    <div class="board-container">
        <div class="gallery-items">
            <div v-for="board in boards" :key="board._id" class="gallery-item" @click="$emit('click', board)">
                <div class="gallery-item__inner">
                    <div class="gallery-item__img" :style="{ backgroundImage: `url('${board.thumb}')` }"></div>
                    <div class="gallery-item__con">
                        <h3 class="gallery-item__tit">{{ board.subject }}</h3>
                        <div class="gallery-item__info">
                            <strong>{{ board.meta.authorName }}</strong><span>{{ board.meta.workType }}</span>
                        </div>
                        <div class="gallery-item__txt">{{ board.summary }}</div>
                        <div v-if="board.meta.buyEnabled" class="gallery-item__btn-wrap">
                            <v-btn @click.prevent="board.meta.buyUrl" color="primary" class="gallery-item__btn">
                                <span>작품보기</span><i class="icon icon-arrow"></i>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <slot name="pagination"></slot>
    </div>
</div>
</template>

<script>
export default {
    props: {
        summary: { type: Object, default () { return { totalCount: 0 }} },
        boards: { type: Array, default: null },
    },
}
</script>

<style lang="scss" scoped>
// Variables
$color-orange: #ffb81d;

// Style
.event-list-container { display: flex; flex-wrap: wrap; margin: -20px -18px; }
.event-list{
	padding: 20px 18px; width: 50%;
	.box { overflow: hidden; display: block; position: relative; border-radius: 15px; }
	.thumbnail { padding-top: calc(292 / 582 * 100%); }
	.thumbnail .image { -webkit-transition: all ease .8s; transition: all ease .8s; }
	.context{
		display: flex; flex-direction: column; justify-content: flex-end;
		z-index: 2; position: absolute; bottom: 0; left: 0; padding: 24px 30px; width: 100%; line-height: 1.4;
		font-size: 16px; color: #ffffff; font-weight: 300; background: rgba(0,0,0,.6);
		.title { display: block; margin-bottom: 6px; font-size: 24px; font-weight: 700; }
	}
	.info{
		display: flex; align-items: center; justify-content: space-between; margin: 10px 10px 0;
		font-size: 16px; color: #555555; font-weight: 300;
		.state { color: $color-orange; font-weight: 700; }
	}
	&--closed .thumbnail .image { filter: grayscale(100%); }
	&--closed .context { height: 100%; }
	&--closed .info .state { color: #d6d6d6; }
}
@media(min-width:1201px){
	.event-list .box:hover .thumbnail .image { -webkit-transform: scale(1.05); transform: scale(1.05); }
}
@media(max-width:1200px){
	.event-list-container { margin: -18px -7px; }
	.event-list{
		padding: 18px 7px;
		.context{
			font-size: 13px;
			.title { font-size: 16px; }
		}
		.info { font-size: 13px; }
	}
}
@media(max-width:768px){
	.event-list-container { margin: -16px 0; }
	.event-list{
		padding: 16px 0; width: 100%;
		.box { border-radius: 12px; }
		.thumbnail { padding-top: calc(320 / 582 * 100%); }
		.context { padding: 18px 20px; }
		.info { margin: 8px 6px 0; }
	}
}
</style>
